import React, { useState, useRef, useEffect } from 'react';
import { client } from 'cccisd-apollo';
import IconQuestion from 'cccisd-icons/question4';
import Loader from 'cccisd-loader';
import { Redirect } from 'cccisd-react-router';
import Tooltip from 'cccisd-tooltip';

import currentLicenseQuery from './currentLicense.graphql';
import voucherQuery from './voucher.graphql';
import style from './style.css';

const Fortress = window.cccisd.fortress;

const RedeemVoucher = () => {
    const [hasCurrentLicense, setHasCurrentLicense] = useState(null);
    const [voucher, setVoucher] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const voucherInputEl = useRef(null);
    const pawnId = Fortress.user.acting.id;

    useEffect(() => {
        async function checkIfLicense() {
            const resp = await client.query({
                query: currentLicenseQuery,
                variables: { pawnId },
                fetchPolicy: 'network-only',
            });
            const ownedLicenses = resp?.data?.products?.ownedLicenseList || [];
            if (ownedLicenses.length === 0) {
                setHasCurrentLicense(false);
                setIsLoading(false);
                return;
            }

            if (ownedLicenses.some(lic => lic.isExpired === false)) {
                setHasCurrentLicense(true);
                return;
            }

            // license expired
            setHasCurrentLicense(false);
            setError('Your current license has expired. Please enter a new voucher code.');
            setIsLoading(false);
        }

        checkIfLicense();
    }, []);

    async function handleSubmit() {
        const code = voucherInputEl.current.value;
        if (!/[\w]{6}/.test(code) || code.length !== 6) {
            setError('The voucher is a 6-digit alphanumeric code.');
            return;
        }

        setIsLoading(true);
        const resp = await client.query({
            query: voucherQuery,
            variables: {
                code,
            },
            fetchPolicy: 'network-only',
        });

        const voucherData = resp.data.products.voucher;
        let err;
        if (!voucherData?.voucherId) {
            err = 'This voucher code is incorrect. Note that voucher codes are case-sensitive.';
        } else if (
            !voucherData?.quantity &&
            voucherData.ownedLicenseList.some(lic => lic.pawnId === pawnId && lic.isExpired)
        ) {
            err = 'Your license has expired. Please enter a new voucher code to access this course.';
        } else if (!voucherData?.quantity) {
            err =
                'This voucher code has already been used. Please enter a different voucher code to access this course.';
        }

        if (err) {
            setError(err);
            setIsLoading(false);
            return;
        }

        setVoucher(voucherData);
        setIsLoading(false);
    }

    if (hasCurrentLicense) {
        return <Redirect to="/product/tmt_course" />;
    }

    if (voucher) {
        return <Redirect to={`/licensing/voucher/redeem/${voucher.code}`} />;
    }

    return (
        <Loader loading={isLoading} removeChildren={hasCurrentLicense === null}>
            <div className={style.voucherForm}>
                <form
                    onSubmit={e => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                >
                    <div className={'form-group ' + (error ? 'has-error' : '')}>
                        <label htmlFor="voucherCode" className="control-label">
                            Enter voucher code:
                            <Tooltip
                                title={
                                    'The voucher code is a 6-digit code required ' +
                                    'to access this course. The code is case-sensitive.'
                                }
                            >
                                <span className="text-primary">
                                    <IconQuestion spaceLeft />
                                </span>
                            </Tooltip>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="voucherCode"
                            autoFocus
                            ref={voucherInputEl}
                            onChange={e => setError('')}
                        />
                        <p className="text-danger">{error}</p>
                    </div>
                    <button className="btn btn-primary" type="button" onClick={() => handleSubmit()}>
                        Submit
                    </button>
                </form>
            </div>
        </Loader>
    );
};

export default RedeemVoucher;
