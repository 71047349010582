import React, { useEffect, useState } from 'react';
import { client } from 'cccisd-apollo';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import { Redirect } from 'cccisd-react-router';
import currentLicenseQuery from './currentLicense.graphql';

const Fortress = window.cccisd.fortress;

const Course = () => {
    const [isValidLicense, setIsValidLicense] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function checkIfLicense() {
            const resp = await client.query({
                query: currentLicenseQuery,
                variables: { pawnId: Fortress.user.acting.id },
                fetchPolicy: 'network-only',
            });

            const ownedLicenses = resp.data.products.ownedLicenseList || [];
            setIsValidLicense(ownedLicenses.some(lic => !lic.isExpired));
            setIsLoading(false);
        }

        checkIfLicense();
    }, []);

    if (isLoading || isValidLicense === null) {
        return <Loader loading />;
    }

    return isValidLicense ? (
        <DeploymentPlayer
            deploymentHandle="main"
            pawnId={Fortress.user.acting.id}
            pawnHash={Fortress.user.acting.random_hash}
        />
    ) : (
        <Redirect to="/redeem" />
    );
};

export default Course;
